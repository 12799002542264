<template>
  <div>
    <base-section
      id="portal"
      class="lightgrey"
      space="0"
    >
      <div style="text-align:center;">
        <h3>System Types</h3>
      </div>
      <div class="col s12">
        <v-tabs
          v-model="tab"
          background-color="transparent"
          color="basil"
          grow
        >
          <v-tab
            v-for="item in items"
            :key="item.value"
            class="systemHeader"
          >
            {{ item.name }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            key="states"
          >
            <v-card
              color="basil"
              flat
            >
              <v-card-text>
                <v-data-table
                  :headers="statesheaders"
                  :items="states"
                  :sort-by="startSortBy_states"
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                      <v-toolbar-title>States</v-toolbar-title>
                      <v-divider
                        class="mx-4"
                        inset
                        vertical
                      ></v-divider>
                      <v-spacer></v-spacer>
                      <v-dialog
                        v-model="statesdialog"
                        max-width="500px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <i class="material-icons float-left">add</i> New State
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title>
                            <span class="headline">{{ formTitle_states }}</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="4"
                                >
                                  <v-text-field
                                    v-model="stateseditedItem.name"
                                    label="Name"
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="4"
                                >
                                  <v-text-field
                                    v-model="stateseditedItem.abbr"
                                    label="Abbr"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="statesclose"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="statessave"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="statesdialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="statescloseDelete">Cancel</v-btn>
                            <v-btn color="blue darken-1" text @click="statesdeleteItemConfirm">OK</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="stateseditItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      @click="statesdeleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <template v-slot:no-data>
                    <v-btn
                      color="primary"
                      @click="initialize"
                    >
                      Reset
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item
            key="usertypes"
          >
            <v-card
              color="basil"
              flat
            >
              <v-card-text>
                <v-data-table
                  :headers="usertypesheaders"
                  :items="usertypes"
                  :sort-by="startSortBy_usertypes"
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                      <v-toolbar-title>User Types</v-toolbar-title>
                      <v-divider
                        class="mx-4"
                        inset
                        vertical
                      ></v-divider>
                      <v-spacer></v-spacer>
                      <v-dialog
                        v-model="usertypesdialog"
                        max-width="500px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <i class="material-icons float-left">add</i> New User Type
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title>
                            <span class="headline">{{ formTitle_usertypes }}</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="4"
                                >
                                  <v-text-field
                                    v-model="usertypeseditedItem.name"
                                    label="Name"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="usertypesclose"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="usertypessave"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="usertypesdialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="usertypescloseDelete">Cancel</v-btn>
                            <v-btn color="blue darken-1" text @click="usertypesdeleteItemConfirm">OK</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="usertypeseditItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      @click="usertypesdeleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <template v-slot:no-data>
                    <v-btn
                      color="primary"
                      @click="initialize"
                    >
                      Reset
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item
            key="questions"
          >
            <v-card
              color="basil"
              flat
            >
              <v-card-text>
                <v-data-table
                  :headers="questionsheaders"
                  :items="questions"
                  :sort-by="startSortBy_questions"
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                      <v-toolbar-title>Questions</v-toolbar-title>
                      <v-divider
                        class="mx-4"
                        inset
                        vertical
                      ></v-divider>
                      <v-spacer></v-spacer>
                      <v-dialog
                        v-model="questionsdialog"
                        max-width="500px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <i class="material-icons float-left">add</i> New Question
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title>
                            <span class="headline">{{ formTitle_questions }}</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="4"
                                >
                                  <v-text-field
                                    v-model="questionseditedItem.question"
                                    label="Question"
                                  ></v-text-field>
                                </v-col>

                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="4"
                                >
                                  <v-text-field
                                    v-model="questionseditedItem.ord"
                                    label="Order"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="questionsclose"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="questionssave"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="questionsdialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="questionscloseDelete">Cancel</v-btn>
                            <v-btn color="blue darken-1" text @click="questionsdeleteItemConfirm">OK</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="questionseditItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      @click="questionsdeleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <template v-slot:no-data>
                    <v-btn
                      color="primary"
                      @click="initialize"
                    >
                      Reset
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </base-section>
  </div>
</template>

<script>
  import { System } from '@/database/system.js'

  export default {
    name: 'SectionMap',
    data: () => ({
      tab: null,
      items: [
        { name: 'States', value: 'states' },
        { name: 'User Types', value: 'usertypes' },
        { name: 'Questions', value: 'questions' },
      ],
      states: [],
      statesdialog: false,
      statesdialogDelete: false,
      stateseditedIndex: -1,
      stateseditedItem: {
        name: '',
        abbr: '',
      },
      statesdefaultItem: {
        name: '',
        abbr: '',
      },
      usertypes: [],
      usertypesdialog: false,
      usertypesdialogDelete: false,
      usertypeseditedIndex: -1,
      usertypeseditedItem: {
        name: '',
      },
      usertypesdefaultItem: {
        name: '',
      },
      questions: [],
      questionsdialog: false,
      questionsdialogDelete: false,
      questionseditedIndex: -1,
      questionseditedItem: {
        question: '',
        ord: 1,
      },
      questionsdefaultItem: {
        question: '',
        ord: 1,
      },
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    }),
    provide: {
      heading: { align: 'center' },
    },
    computed: {
      conditionsheaders () {
        var header = [
          {
            text: 'id',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Name', value: 'name' },
          { text: 'Note', value: 'note' },
          { text: 'Actions', value: 'actions', sortable: false },
        ]
        
        return header
      },
      statesheaders () {
        var header = [
          {
            text: 'id',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Name', value: 'name' },
          { text: 'Abbr', value: 'abbr' },
          { text: 'Actions', value: 'actions', sortable: false },
        ]
        
        return header
      },
      formTitle_states () {
        return this.stateseditedIndex === -1 ? 'New Item' : 'Edit Item'
      },
      startSortBy_states () {
        return 'Name'
      },
      usertypesheaders () {
        var header = [
          {
            text: 'id',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Name', value: 'name' },
          { text: 'Actions', value: 'actions', sortable: false },
        ]
        
        return header
      },
      formTitle_usertypes () {
        return this.usertypeseditedIndex === -1 ? 'New Item' : 'Edit Item'
      },
      startSortBy_usertypes () {
        return 'Name'
      },
      questionsheaders () {
        var header = [
          {
            text: 'id',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Question', value: 'question' },
          { text: 'Order', value: 'ord' },
          { text: 'Actions', value: 'actions', sortable: false },
        ]
        
        return header
      },
      formTitle_questions () {
        return this.questionseditedIndex === -1 ? 'New Item' : 'Edit Item'
      },
      startSortBy_questions () {
        return 'Name'
      },
    },
    watch: {
      statesdialog (val) {
        val || this.statesclose()
      },
      statesdialogDelete (val) {
        val || this.statescloseDelete()
      },
      usertypesdialog (val) {
        val || this.usertypesclose()
      },
      usertypesdialogDelete (val) {
        val || this.usertypescloseDelete()
      },
      questionsdialog (val) {
        val || this.questionsclose()
      },
      questionsdialogDelete (val) {
        val || this.questionscloseDelete()
      },
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        this.fetchSystemItem('states')
        this.fetchSystemItem('usertypes')
        this.fetchSystemItem('questions')
      },
      fetchSystemItem (systemtypename) {
        System.findOne(systemtypename).then(doc => {
          switch (systemtypename){
            case 'states':
              this.states = [...doc.data().data]
              break
            case 'usertypes':
              this.usertypes = [...doc.data().data]
              break
            case 'questions':
              this.questions = [...doc.data().data]
              break
          }
        })
      },
      updateSystem (id, dataArr) {
        System.update(id, { data: dataArr }).then(() => {
          // update the Item
        })
      },
      stateseditItem (item) {
        this.stateseditedIndex = this.states.indexOf(item)
        this.stateseditedItem = Object.assign({}, item)
        this.statesdialog = true
      },

      statesdeleteItem (item) {
        this.stateseditedIndex = this.states.indexOf(item)
        this.stateseditedItem = Object.assign({}, item)
        this.statesdialogDelete = true
      },

      statesdeleteItemConfirm () {
        this.states.splice(this.stateseditedIndex, 1)
        this.updateSystem('states', this.states)
        this.statescloseDelete()
      },

      statesclose () {
        this.statesdialog = false
        this.$nextTick(() => {
          this.stateseditedItem = Object.assign({}, this.statesdefaultItem)
          this.stateseditedIndex = -1
        })
      },

      statescloseDelete () {
        this.statesdialogDelete = false
        this.$nextTick(() => {
          this.stateseditedItem = Object.assign({}, this.statesdefaultItem)
          this.stateseditedIndex = -1
        })
      },

      statessave () {
        if (this.stateseditedIndex > -1) {
          Object.assign(this.states[this.stateseditedIndex], this.stateseditedItem)
        } else {
          this.states.push(this.stateseditedItem)
        }
        this.updateSystem('states', this.states)
        this.statesclose()
      },
      usertypeseditItem (item) {
        this.usertypeseditedIndex = this.usertypes.indexOf(item)
        this.usertypeseditedItem = Object.assign({}, item)
        this.usertypesdialog = true
      },

      usertypesdeleteItem (item) {
        this.usertypeseditedIndex = this.usertypes.indexOf(item)
        this.usertypeseditedItem = Object.assign({}, item)
        this.usertypesdialogDelete = true
      },

      usertypesdeleteItemConfirm () {
        this.usertypes.splice(this.usertypeseditedIndex, 1)
        this.updateSystem('usertypes', this.usertypes)
        this.usertypescloseDelete()
      },

      usertypesclose () {
        this.usertypesdialog = false
        this.$nextTick(() => {
          this.usertypeseditedItem = Object.assign({}, this.usertypesdefaultItem)
          this.usertypeseditedIndex = -1
        })
      },

      usertypescloseDelete () {
        this.usertypesdialogDelete = false
        this.$nextTick(() => {
          this.usertypeseditedItem = Object.assign({}, this.usertypesdefaultItem)
          this.usertypeseditedIndex = -1
        })
      },

      usertypessave () {
        if (this.usertypeseditedIndex > -1) {
          Object.assign(this.usertypes[this.usertypeseditedIndex], this.usertypeseditedItem)
        } else {
          this.usertypes.push(this.usertypeseditedItem)
        }
        this.updateSystem('usertypes', this.usertypes)
        this.usertypesclose()
      },
      questionseditItem (item) {
        this.questionseditedIndex = this.questions.indexOf(item)
        this.questionseditedItem = Object.assign({}, item)
        this.questionsdialog = true
      },

      questionsdeleteItem (item) {
        this.questionseditedIndex = this.questions.indexOf(item)
        this.questionseditedItem = Object.assign({}, item)
        this.questionsdialogDelete = true
      },

      questionsdeleteItemConfirm () {
        this.questions.splice(this.questionseditedIndex, 1)
        this.updateSystem('questions', this.questions)
        this.questionscloseDelete()
      },

      questionsclose () {
        this.questionsdialog = false
        this.$nextTick(() => {
          this.questionseditedItem = Object.assign({}, this.questionsdefaultItem)
          this.questionseditedIndex = -1
        })
      },

      questionscloseDelete () {
        this.questionsdialogDelete = false
        this.$nextTick(() => {
          this.questionseditedItem = Object.assign({}, this.questionsdefaultItem)
          this.questionseditedIndex = -1
        })
      },

      questionssave () {
        if (this.questionseditedIndex > -1) {
          Object.assign(this.questions[this.questionseditedIndex], this.questionseditedItem)
        } else {
          this.questions.push(this.questionseditedItem)
        }
        this.updateSystem('questions', this.questions)
        this.questionsclose()
      },
    },
  }
</script>
<style>
/* Helper classes */
.basil {
  background-color: #FFFBE6 !important;
}
.basil--text {
  color: #356859 !important;
}
.systemHeader {
  
}
</style>
